




















import { Component } from 'vue-property-decorator'
import myMixins from '@/mixins/common.ts'
import API from '@/api/index.js'

@Component({
    components: {}
})
export default class VerificationCode extends myMixins {
    timer = null
    smsCode = ''

    mounted() {
        // 第一个input聚焦
        const ref = this.$refs['inp'] as any
        ref.focus()
        // 刷新定时器重启
        const { timer, timeNumber } = this.$store.state.login
        if (!timer && timeNumber > 0) {
            this.getYZM()
        }
    }

    get getTimeNumber() {
        return this.$store.state.login.timeNumber < 0 ? 0 : this.$store.state.login.timeNumber
    }

    get yzmPhone() {
        return this.$store.state.login.timePhone
    }

    smsCodeChange() {
        if (this.smsCode.length === 6) {
            this.checkYzm()
        }
    }

    async checkYzm() {
        const { timeAction, timePhone } = this.$store.state.login

        const res = await API.checkYzm({
            action: timeAction,
            mobile: timePhone,
            smsCode: this.smsCode
        })
        //  1 注册 2 登录 3 重置密码 4 解绑手机 5 绑定手机
        if (res && res.data && res.data.errorCode === 200 && res.data.data.status) {
            this.$store.commit('login/updateTimeSmsCode', this.smsCode)
            const params = {
                phone: timePhone,
                smsCode: this.smsCode
            }
            switch (timeAction) {
                case 1:
                    this.$store.commit('login/updateTimeNumber', -1)
                    this.$nextTick(() => {
                        this.routerChange('setPassword')
                    })
                    break
                case 2:
                    this.loginPhone(params)
                    break
                case 3:
                    this.$store.commit('login/updateTimeNumber', -1)
                    this.$nextTick(() => {
                        this.routerChange('resetPassword')
                    })
                    break
                case 4:
                    this.bindPhoneCheck(params)
                    break
                case 5:
                    this.bindPhone(params)
                    break
                default:
                    console.error('验证码类型不能为空')
                    break
            }
        } else {
            this.$toast('验证码错误，请重新输入')
            this.$nextTick(() => {
                const ref = this.$refs['inp'] as any
                ref.focus()
            })
        }
    }

    async bindPhoneCheck(params: Param) {
        const res = await API.bindPhoneCheck(params)
        if (res && res.data && res.data.errorCode === 200) {
            this.resetStoreStatus()
            this.$nextTick(() => {
                this.routerChange('newPhone')
            })
        }
    }

    async bindPhone(params: Param) {
        const res: any = await API.bindPhone(params)
        if (res && res.data && res.data.errorCode === 200) {
            this.resetStoreStatus()
            this.$nextTick(() => {
                this.routerChange('personalInfo')
            })
        }
    }

    async loginPhone(params: Param) {
        const { canPhoneBindWechat, wxTokenID } = this.$store.state.login
        if (canPhoneBindWechat && wxTokenID) {
            params.tokenID = wxTokenID
        }
        const res = await API.loginByPhoneSmsCode(params)
        // 登录时不重置倒数数字
        this.$store.commit('login/updateCanPhoneBindWechat', false)
        this.$store.commit('login/updateTimePhone', '')
        this.$store.commit('login/updateTimeAction', '')
        this.$store.commit('login/updateTimeSmsCode', '')

        this.login(res)
    }

    resetStoreStatus() {
        // 验证成功后重置： 倒计时 记录的手机号 验证码 验证类型
        this.$store.commit('login/updateTimeNumber', -1)
        this.$store.commit('login/updateTimePhone', '')
        this.$store.commit('login/updateTimeAction', '')
        this.$store.commit('login/updateTimeSmsCode', '')
        this.clearTimer()
    }

    // 手机重新发送验证码
    sendYzm() {
        const { timeNumber, timePhone, timeAction } = this.$store.state.login
        if (timeNumber < 0) {
            this.getYZM()
            API.sendForYzm({
                mobile: timePhone,
                action: timeAction
            })
        }
    }
}
